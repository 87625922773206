export default {
    addNew: {
        place_idNumber: "Please enter User ID Number",
        idNumber: "User ID Number",
        exceptionNumber: "The quantity is incorrect",
        walletLink: "Please open this connection in your wallet！",
        connectWallet: "Connect wallet",
        my_wallet: "My wallet",
        add_wallet: "Add wallet",
        place_wallet_type: "Please select the wallet type",
        place_wallet_name: "Please enter the wallet name",
        place_wallet_address: "Please enter the wallet address",
        reference_annualization: "Reference annualization",
        state: "state",
        term: "time limit",
        depositState0: "In progress",
        depositState1: "Stay tuned",
        label_amount: "Amount",
        place_amount: "Please enter the amount",
        estimated_income: "Estimated revenue",
        available_assets: "Available assets",
        cancel: "Cancel",
        all: "All",
        min_amount: "Minimum amount",
        daily: "Daily Production",
        waiting_time: "Waiting Time",
        product_name: "product name",
        buy_total: "Total quantity purchased",
        earnings: "cumulative gain",

        myMessage: "Message",
        place_card_back: "Click to upload a photo of the back of your ID card",
        side1: "Order Management",
        side2: "Change password",
        side3: "Language",
        side4: "Log out",
        product: "Product",
        direction: "Direction",
        cycle: "Transaction period",
        long_buy: "Buy long",
        short_sell: "Short sell",
        open_time: "Opening time",
        close_time: "Closing Time",
        open_price: "Opening price",
        close_price: "Closing price",
        number: "Quantity",
        profit_loss: "Profit and Loss",
        commission: "Handling fee",
        account_type: "Account type",
        type1: "Coin",
        type2: "Legal",
        type3: "Contract",
        type4: "Option",
        type5: "Finance",
        message1: "Please enter the amount",
        message2: "Insufficient account balance",
        place_transPsd: "Please enter transaction password",
        countpath: "Automatically jump to the order page after the countdown ends",
        nowpath: "Jump immediately",
    },
    foot: {
        nav1: "Home",
        nav2: "Market",
        nav3: "Exchange",
        nav4: "Exchange",
        nav5: "Assets",
    },
    addnew: {
        score: "Score",
		reference_annualization: "Reference annualization",
		state: "state",
		term: "time limit",
		depositState0: "In progress",
		depositState1: "Stay tuned",
		label_amount: "Amount",
		place_amount: "Please enter the amount",
		estimated_income: "Estimated revenue",
		available_assets: "Available assets",
		cancel: "Cancel",
		all: "All",
		min_amount: "Minimum amount",
		daily: "Daily Production",
		waiting_time: "Waiting Time",
		product_name: "product name",
		buy_total: "Total quantity purchased",
		earnings: "cumulative gain",
        close_p1: "Entry price",
        close_p2: "Stop profit",
        close_p3: "Equivalent quantity",
        close_p4: "Current price",
        close_p5: "Stop loss price",
        close_p6: "Available margin",
        close_p7: "Caution money",
        close_p8: "Handling fee",
        close_p9: "Multiples",
        close_p10: "Earnings(USDT)",
        close_p11: "Open time",
    },
    account: {
        verifySuccess: "Verified",
        verifyFailed: "Not Verify",
        login: "Login",
        register_email: "Email",
        register_phone: "Phone",
        label_phone: "Phone",
        place_phone: "Please enter your phone number",
        label_eMail: "Email",
        place_eMail: "Please enter your email address",
        tip_eMail: "Please enter your email address correctly",
        label_password: "Password",
        place_password: "Please enter your password",
        tip_password: "Please enter the correct password",
        no_account: "Don't have an account yet?" ,
        register: "Register",
        send: "Send verification code",
        label_code: "Verification code",
        place_code: "Verification code",
        tip_code: "Please enter Verification code",
        label_invitation: "Invitation code",
        place_invitation: "Please enter the invitation code",
    },
    assets: {
        title: "property",
        total_assets: "Total Assets",
        recharge: "Deposit",
        withdraw: "Withdraw",
        available: "Available",
        freeze: "Pending",
        total: "Total",
        label_bank: "Currency",
        place_bank: "Please select a currency",
        tip_bank: "Please select a currency",
        label_wallet: "Wallet",
        place_wallet: "Please select a wallet",
        tip_wallet: "Please select a wallet",
        place_amount: "Please enter the amount",
        tip_amount: "Please enter the amount correctly",
        label_password: "Password",
        place_password: "Please enter the password",
        tip_password: "Please enter the correct password",
        label_balance: "Available USDT",
        all: "All",
        no_password: "For the safety of your funds, please set up",
        withdraw_psd: "Withdrawal password",
        type1: "Exchange",
        type2: "Fiat",
        type3: "Contract",
        type4: "Second Futures",
        type5: "Financial",
        convert: "Equivalent",
        now_currency: "Current currency",
        link_type: "CHAIN TYPE",
        recharge_address: "Deposit Address",
        copy: "Copy address",
        label_amount: "Deposit Amount",
        palce_amount: "Please enter the Deposit Amount",
        label_address: "Wallet Address",
        palce_address: "Please enter the wallet address",
        submit_process: "Submit",
        tip1: "Please do not recharge any non USDT assets to the above address, otherwise the assets will not be recoverable。",
        tip2: "USDT coin charging only supports the simple send method, and coins using other methods cannot be credited at the moment  Please understand。",
        tip3: "After you recharge to the above address, you need confirmation from the entire network node  Once the network confirmation is made, the account will be credited, and once the network confirmation is made, the coin can be withdrawn。",
        tip4: "Your recharge address will not change frequently and can be recharged repeatedly; If there are any changes, we will try our best to notify you through online announcements or emails 。",
        tip5: "Please ensure the security of your computer and browser to prevent information from being tampered with or leaked。",
        record_title: "Transaction records",
        label_number: "Number",
        label_record: "Record",
        label_time: "Time",
        label_price: "Price",
        label_volume: "Volume",
        transfer_title: "Transfer",
        from: "from",
        to: "to",
        transfer_record: "Transfer Record",
        label_withAddress: "Withdrawal address",
        place_withAddress: "Please set your withdrawal address first",
        deposit_title: "Pledge mining",
        buy: "Buy",
        sell: "Sell",
        week: "Period",
        daily_dividend: "Daily dividend",
        deposit_log: "Pledge mining records",
        userinfo_nav1: "Authentication",
        userinfo_nav2: "My sharing link",
        userinfo_nav3: "My authorization code",
        userinfo_nav4: "Security center",
        userinfo_nav5: "Collection method",
        userinfo_nav6: "Online Service",
        userinfo_nav7: "About us",
        userinfo_nav8: "download APP",
        userinfo_nav9: "Help center",
        logout: "Log out",
        second: "Seconds",
        second_title: "Speed contract",
        invite: "invite",
        legal: "Fiat",
        ieo: "IEO",
        state0: "All",
        state1: "Pre-sale",
        state2: "Subscription",
        state3: "Ended",
        history_record: "History",
        legal_tab1: "I want to buy",
        legal_tab2: "I want to sell",
        transType: "transaction type",
        order_state: "Status",
        order_state1: "incomplete",
        order_state2: "paid",
        order_state3: "Delay confirmation",
        order_state4: "Defending rights",
        order_state5: "Canceled",
        order_state6: "Completed",
        reset: "resetting",
        hand_number: "Number of trading hands",
        long: "Go Long",
        short: "Short selling",
        confirm_order: "Confirm order placement",
        type: "Type",
        multiple_number:"multiple",
        now_entrust: "Current delegation",
        all_entrust: "Full delegation",
        history_entrust: "Historical commission",
        turnover: "Turnover",
        operate: "operate",
        delete: "delete",
        order_details: "Order details",
        orderDetails_tab1: "In the list",
        orderDetails_tab2: "Transaction",
        orderDetails_tab3: "Closed",
        orderDetails_tab4: "Revoked",
        risk_rate: "risk rate",
        total_pl: "Total profit and loss of holdings",
        closing_position: "One key closing",
        position_list: "Position List",
        order_record: "Order Record",
        profit_rate: "profit rate",
        buy_long: "Buy up",
        buy_short: "Buy down",
        trans_mode: "transactional mode",
        opening_quantity: "Opening quantity",
        opening_number: "Open Time",
        place_transMount: "Please enter the transaction limit",
        account_balance: "Account balance",
        privacy_policy: "Privacy Policy",
        contact_us: "Contact Us",
        feedback: "Feedback",
        feedback_type: "Feedback Type",
        feedback_type1: "Withdrawal related",
        feedback_type2: "Blockchain related",
        label_title: "title",
        place_title: "Please enter a title",
        label_cont: "Feedback Content",
        place_cont: "Please enter content",
        reset_password: "reset password",
        personal_center: "Personal Center",
        to_authenticate: "To authenticate",
        authenticate_tip1: "·After authentication, the withdrawal limit can be increased",
        authenticate_tip2: "·Real name authentication is required to perform legal currency transaction withdrawal operations",
        payment_method: "payment method",
        label_reaName: "Name",
        palce_reaName: "Name",
        label_openBank: "BANK",
        palce_openBank: "Please enter the account opening bank",
        label_bankNumber: "Bank number",
        place_bankNumber: "Please enter your bank card number",
        lable_branchBank: "Bank branch",
        place_branchBank: "Please enter the name of the account opening branch",
        label_IDnumber: "ID",
        place_IDnumber: "Please enter ID number number",
        label_IDimg: "Handheld ID card photo",
        label_IDimg2: "Photo of ID card on the back",
        tip_IDimg: "Please upload a photo of the back of your ID card",
        bankCard: "Bank Card",
        details: "details",
        my_authorization_code: "My authorization code",
        my_authorization_code_tip: "My authorization code",
        login_password: "Login password",
        trans_password: "Transaction password",
        modify: "modify",
        my_promotional_code: "My promotional code",
        promotional_code: "Promotion code",
        promotional_code_tip: "Scan the QR code pattern above or enter the promotion code to confirm registration",
        copy_link: "Copy  link",
        modify_trans_psd: "Change transaction password",
        label_email_verify: "Email verification",
        place_email_verify: "Please enter the verification code",
        send: "sending",
        email_verify_tip: "The verification code will be sent to your email",
        label_bitTitle: "Pairs",
    },
    currency: {
        position: "Position",
        drawer_title: "Options",
        now: "Price",
        open: "Open",
        volume: "Amount",
        height: "High",
        low: "Low",
        buy: "Buy/Long",
        sell: "Sell/Short",
        name: "Name",
        direction: "Direction",
        crm_order: "Confirm order",
        now_price: "Price",
        type_time: "Delivery time (left slide for higher returns)",
        second: "s",
        buy_amount: "Amount",
        available: "Available",
        service_fee: "Promise",
        min_number: "Minimunm buy ",
        errorTip1: "Incorrect amount input",
        errorTip2: "Insufficient available balance",
        auto_jump: "Automatically jump to order after",
        keep: "Continue placing orders",
        revenue: "Expected revenue",
    },
    home: {
        quick: "Quick coin flush",
        support: "Support",
        nav1: "Share",
        nav2: "Wallet",
        nav3: "News",
        nav4: "Tutorials",
        nav5: "Live chat",
        nav6: "Mining",
        nav7: "About Us",
        nav8: "New Coin",
        increase: "Top Gainers",
        volume: "Top Leaders",
        table_name: "Name",
        table_lastPrice: "Price",
        table_increase: "Change",
        table_volume: "24H VOL",
    },
    lockming: {
        profit: "Recent (daily earnings)",
        cycle: "financial cycle",
        limit: "Single limit",
        dividend_time: "Dividend payout time",
        every_day: "daily",
        funds: "Escrow funds",
        back: "Return on expiry",
        ransom: "Early redemption",
        label_amount: "Pledge Amount",
        place_amount: "Please enter,,,",
        available: "available",
        expect_income: "Expected income",
        title: "Locked Mining",
        tip: "Mining for income",
        label_today_income: "Estimated earnings today",
        label_total_income: "Cumulative income",
        label_order: "Orders in escrow",
        label_number: "Single amount",
        daily_return: "Daily return",
        subscribe: "subscribe",
        position: "Position",
        label_state: "Status",
        subscribe_time: "subscription_time",
        expiration_time: "expiration time",
        income: "income",
        errorTip1: "Please enter the amount",
        tip_tit: "Are you sure you want to exit early",
    },
    market: {
        spots: "Spots",
        contract: "Futures",
        currency_trans: "Options",
        increase: "Top Gainers",
        volume: "Top Leaders",
        label_name: "Name",
        label_lastPrice: "Price",
        table_increase: "Change",
        table_volume: "24H VOL",
    },
    my: {
        details: "Details",
        help: "Help Center",
        information: "Information",
        language: "Language switch",
        password: {
            account_title: "Change password",
            withdraw_title: "Change password",
            label_oldPsd: "Old password",
            place_oldPsd: "Please enter your old password",
            label_newPsd: "New password",
            place_newPsd: "Please enter a new password",
            label_crmPsd: "Confirm password",
            place_crmPsd: "Please enter a new password again",
            withdraw_tip: "If you have not set a withdrawal password, please leave it blank",
        },
        address: {
            title: "Wallet address",
            account_number: "Account number",
            add_btn: "Add address",
            label_type: "Select type",
            place_type: "Please select type",
            label_number: "Account",
            place_number: "Please enter the account number",
        },
        history: {
            title: "History",
            recharge_record: "Recharge record",
            withdraw_record: "Withdrawal record",
            withdraw: "Withdrawals",
            number: "quantity",
            currency: "Amount",
            state: "Status",
            state0: "Pending",
            state1: "Completed",
            state2: "Rejected",
        },
        identity: {
            title: "Certification",
            label_country: "Nationality",
            place_country: "Please select a nationality",
            label_name: "Name",
            place_name: "Please enter your name",
            label_id: "ID number",
            place_id: "Please enter your ID/driver's license/passport number",
        },
        my: {
            list1: "Certification",
            list2: "Order History",
            list3: "Record",
            list4: "Wallet address",
            list5: "Switch language",
            list6: "Invite Friends",
            list7: "Customer Service",
            list8: "Tutorials",
            list9: "Change password",
            logout: "Sign out",
        },
        order: {
            title: "Order History",
            direction: "Direction",
            sell_price: "Order price",
            number: "Quantity",
            type: "Delivery time",
            buy_price: "Buy price",
            profit: "P/L",
            sell_time: "Sell time",
        },
        share: {
            title: "Invite friends",
            save_ewm: "Save QR code",
            invite_link: "Invite link",
            copy_address: "Copy address",
            invite_code: "Registration code",
            copy_code: "Copy content",
            tip: "Register via the QR code or link above",
        },
    },
    transaction: {
        spots: "Spot",
        contract: "Futures",
        currency_trans: "Options",
        exchange: "Swap",
        sustainable: "Perpetual",
        warehouse: "Isolated",
        jump_lever: "Adjust Leverage",
        sell: "Sell",
        buy: "Buy",
        available: "Available",
        check_price: "Limit",
        market_price: "market",
        number: "Quantity",
        max: "Max",
        bail: "Cost",
        buy_long: "Buy/long",
        sell_short: "Sell/short ",
        last_price: "Price",
        now_entrust: "Open Orders",
        now_position: "Position",
        table_amount: "Amount",
        is_reduce: "Reduce Only",
        shi: "true",
        fou: "false",
        position_number: "Size",
        is_profit: "Unrealized PNL",
        wei: "not yet",
        open_price: "Entry price",
        mark_price: "Mark price",
        flat_price: "Liquidation",
        trim_lever: "Adjust leverage",
        flat_store: "Close",
        trim_bail: "Adjust margin",
        reduce_bail: "reduce_margin",
        now_bail: "Current position margin",
        max_add: "Maximum increase",
        refer_flat: "Adjusted reference strong flat price",
        expect_profit: "Expected profit/loss"
    },
    common: {
        confirm: "Confirm",
        Cancel: "Cancle",
        all: "All",
        close: "Cancel",
        copy_success: "Copy successful!" ,
        copy_error: "Copy failed!" ,
        place_input: "Please enter content",
        more: "More",
        main: "Main",
        sub: "Subp",
        success: "Success!",
        cancel_tip: "Are you sure you want to cancel?" ,
        notice: "Notification",
        logout_tip: "Are you sure to exit?",
        tip: "Tip",
        day: "Day",
        empty: "No Data"
    },
    message: {
		cancel_order: "Whether to revoke the delegation?",
		stop_out: "Whether to forcibly close the position?",
	},
    params: {
        transType0: "Spot",
        transType1: "Contract",
        directionType0: "Buy",
        directionType1: "Sell",
        lockStateType0: "Operation",
        lockStateType1: "Ended",
        lockStateType2: "Canceled",
    },
    city: {
        albania: "Albania",
        algeria: "Algeria",
        argentina: "Argentina",
        armenia: "Armenia",
        australia: "Australia",
        pakistan: "Pakistan",
        austria: "Austria",
        bahrain: "Bahrain",
        belgium: "Belgium",
        bosnia_and_Herzegovina: "Bosnia and Herzegovina",
        brazil: "Brazil",
        brunei: "Brunei",
        bulgaria: "Bulgaria",
        cambodia: "Cambodia",
        canada: "Canada",
        cameroon: "Cameroon",
        chile: "Chile",
        colombia: "Colombia",
        costa_Rica: "Costa Rica",
        croatia: "Croatia",
        cyprus: "Cyprus",
        czech_Republic: "Czech Republic",
        denmark: "Denmark",
        dominican_Republic: "Dominican Republic",
        egypt: "Egypt",
        estonia: "Estonia",
        ethiopia: "Ethiopia",
        finland: "Finland",
        france: "France",
        georgia: "Georgia",
        germany: "Germany",
        ghana: "Ghana",
        greece: "Greece",
        guyana: "Guyana",
        honduras: "Honduras",
        hong_Kong_China: "Hong Kong, China",
        hungary: "Hungary",
        iceland: "Iceland",
        ireland: "Ireland",
        italy: "Italy",
        india: "India",
        indonesia: "Indonesia",
        israel: "Israel",
        iran: "Iran",
        iraq: "Iraq",
        japan: "Japan",
        kazakstan: "Kazakhstan",
        kenya: "Kenya",
        korea: "Korea",
        kuwait: "Kuwait",
        kyrgyzstan: "Kyrgyzstan",
        laos: "Laos",
        latvia: "Latvia",
        lithuania: "Lithuania",
        luxembourg: "Luxembourg",
        macao_China: "Macao_China",
        macedonia: "Macedonia",
        malaysia: "Malaysia",
        malta: "Malta",
        mexico: "Mexico",
        moldova: "Moldova",
        monaco: "Monaco",
        mongolia: "Mongolia",
        montenegro: "Montenegro",
        morocco: "Morocco",
        myanmar: "Myanmar",
        netherlands: "Netherlands",
        new_Zealand: " New_Zealand",
        nepal: "Nepal",
        nigeria: "Nigeria",
        norway: "Norse",
        oman: "Oman",
        palestine: "Palestine",
        panama: "Panama",
        paraguay: "Paraguay",
        peru: "Peru",
        philippines: "Philippines",
        poland: "Poland",
        portugal: "Portugal",
        puerto_Rico: "Puerto Rico",
        qatar: "Qatar",
        romania: "Romania",
        russia: "Russia",
        republic_of_Trinidad_and_Tobago: "Republic of Trinidad and Tobago",
        rwanda: "Rwanda",
        saudi_Arabia: "Saudi Arabia",
        serbia: "Serbia",
        singapore: "Singapore",
        slovakia: "Slovakia",
        slovenia: "Slovenia",
        south_Africa: "South Africa",
        spain: "Spain",
        sri_Lanka: "Sri Lanka",
        sweden: "Sweden",
        switzerland: "Switzerland",
        taiwan_China: "Taiwan_China",
        tajikistan: "Tajikistan",
        tanzania: "Tanzania",
        thailand: "Thailand",
        turkey: "Turkey",
        turkmenistan: "Turkmenistan",
        ukraine: "Ukraine",
        united_Arab_Emirates: "United Arab Emirates",
        united_Kingdom: "United Kingdom",
        united_States: " United_States",
        uzbekistan: "Uzbekistan",
        venezuela: "Venezuela",
        vietnam: "Vietnam",
        afghanistan: "Afghanistan",
        angola: "Angola",
        azerbaijan: "Azerbaijan",
        bangladesh: "Bangladesh",
        belarus: "Belarus",
        belize: "Belize",
        benin: "Benin",
        bhutan: "Bhutan",
        bolivia: "Bolivia",
        botswana: "Botswana",
        british_Virgin_Islands: "British Virgin Islands",
        burkina_Faso: "Burkina Faso",
        burundi: "Burundi",
        cape_Verde: "Cape Verde",
        cayman_Islands: "Cayman Islands",
        central_African_Republic: "Central African Republic",
        chad: "Chad",
        comoros: "Comoros",
        the_Republic_of_the_Congo: " The_Republic_of_the_Congo",
        democratic_Republic_of_the_Congo: "Democratic Republic of the Congo",
        djibouti: "Djibouti",
        ecuador: "Ecuador",
        el_Salvador: "El Salvador",
        equatorial_Guinea: "Equatorial Guinea",
        eritrea: "Eritrea",
        fiji: "Fiji",
        gabon: "Gabon",
        gambia: "Gambia",
        greenland: "Greenland",
        guatemala: "Guatemala",
        guinea: "Guinea",
        haiti: "Haiti",
        isle_of_Man: " Isle_of_Man",
        cote_d_Ivoire: "Cote_d_Ivoir",
        jamaica: "Jamaica",
        jordan: "Jordan",
        lebanon: "Lebanon",
        lesotho: "Lesotho",
        liberia: "Liberia",
        libya: "Libya",
        madagascar: "Madagascar",
        malawi: "Malawi",
        maldives: "Maldives",
        mali: "Mali",
        mauritania: "Mauritania",
        mauritius: "Mauritius",
        mozambique: "Mozambique",
        namibia: "Namibia",
        nicaragua: "Nicaragua",
        republic_of_Niger: "Republic_of_Niger",
        north_Korea: " North_Korea",
        reunion: "Reunion",
        san_Marino: "SAN Marino",
        senegal: "Senegal",
        sierra_Leone: "Sierra Leone",
        somalia: "Somalia",
        sudan: "Sudan",
        suriname: "Suriname",
        eswatini: "Eswatini",
        syria: "Syria",
        togo: "Togo",
        tonga: "Tonga",
        tunisia: "Tunisia",
        united_States_Virgin_Islands: "United States Virgin Islands",
        uganda: "Uganda",
        uruguay: "Uruguay",
        vatican: "Vatican",
        yemen: "Yemen",
        yugoslavia: "Yugoslavia",
        zambia: "Zambia",
        zimbabwe: "Zimbabwe",
        china: "China",
     }
};
