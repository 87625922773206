export default function lang() {
    let lang = navigator.language; //浏览器语言判断
    lang = lang.substr(0, 2);
    switch (lang) {
        case "zh": //中文
            lang = "zh";
            break;
        case "tc": //中文
            lang = "tc";
            break;
        case "en": //英文
            lang = "en";
            break;
        case "jp": //日语
            lang = "jp";
            break;
        case "ko": //韩语
            lang = "ko";
            break;
            case "de": //德语
            lang = "de";
            break;
            case "es": //西班牙
            lang = "es";
            break;
            case "fr": //法语
            lang = "fr";
            break;
            case "pt": //葡萄牙
            lang = "pt";
            break;

        default:
            lang = "en";
    }
    return lang;
}