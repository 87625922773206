export default {
    addNew: {
        idNumber: "Número de identificación del usuario",
        exceptionNumber: "Cantidad incorrecta",
        walletLink: "Abra esta conexión en su billetera！",
        connectWallet: "Conexión a la billetera",
        my_wallet: "My wallet",
        add_wallet: "Add wallet",
        place_wallet_type: "Please select the wallet type",
        place_wallet_name: "Please enter the wallet name",
        place_wallet_address: "Please enter the wallet address",
        reference_annualization: "Reference annualization",
        state: "state",
        term: "time limit",
        depositState0: "In progress",
        depositState1: "Stay tuned",
        label_amount: "Amount",
        place_amount: "Please enter the amount",
        estimated_income: "Estimated revenue",
        available_assets: "Available assets",
        cancel: "Cancel",
        all: "All",
        min_amount: "Minimum amount",
        daily: "Daily Production",
        waiting_time: "Waiting Time",
        product_name: "product name",
        buy_total: "Total quantity purchased",
        earnings: "cumulative gain",

        myMessage: "Message",
        place_card_back: "Click to upload a photo of the back of your ID card",
        side1: "Order Management",
        side2: "Change password",
        side3: "Language",
        side4: "Log out",
        product: "Product",
        direction: "Direction",
        cycle: "Transaction period",
        long_buy: "Buy long",
        short_sell: "Short sell",
        open_time: "Opening time",
        close_time: "Closing Time",
        open_price: "Opening price",
        close_price: "Closing price",
        number: "Quantity",
        profit_loss: "Profit and Loss",
        commission: "Handling fee",
        account_type: "Account type",
        type1: "Coin",
        type2: "Legal",
        type3: "Contract",
        type4: "Option",
        type5: "Finance",
        message1: "Please enter the amount",
        message2: "Insufficient account balance",
        place_transPsd: "Please enter transaction password",
        countpath: "Automatically jump to the order page after the countdown ends",
        nowpath: "Jump immediately",
    },
    foot: {
        nav1: "Inicio",
        nav2: "Cotizaciones",
        nav3: "Spot",
        nav4: "Futuros",
        nav5: "Activos",
    },
    addnew: {
        score: "Score",
		reference_annualization: "Referencia Anualizada",
		state: "Situación",
		term: "Plazos",
		depositState0: "Caliente en marcha.",
		depositState1: "Esperamos",
		label_amount: "Suma de dinero",
		place_amount: "Introduzca el importe",
		estimated_income: "Ingresos previstos",
		available_assets: "Activo disponible",
		lockStateType2: "Cancelado",
		all: "Completo",
		min_amount: "Importe mínimo",
		daily: "Producción diaria",
		waiting_time: "Tiempo de espera",
		product_name: "Cloruro de dietilamonio",
		buy_total: "Número total de compras",
		earnings: "Ganancia acumulada",
        close_p1: "Precio de apertura",
        close_p2: "Parche",
        close_p3: "Rendimiento",
        close_p4: "Precio actual",
        close_p5: "Precio de mercado",
        close_p6: "Margen disponible",
        close_p7: "Margen",
        close_p8: "Gastos de tramitación",
        close_p9: "Factor",
        close_p10: "Proceda",
        close_p11: "Horarios de apertura",
    },
    account: {
        verifySuccess: "Certificación aprobada",
        verifyFailed: "No certificado",
        login: "Iniciar sesión",
        register_email: "Registro por correo electrónico",
        register_phone: "Registro por teléfono",
        label_phone: "Número de teléfono",
        place_phone: "Ingrese su número de teléfono",
        label_eMail: "Correo electrónico",
        place_eMail: "Ingrese su dirección de correo electrónico",
        tip_eMail: "Ingrese una dirección de correo electrónico válida",
        label_password: "Contraseña",
        place_password: "Ingrese la contraseña",
        tip_password: "Ingrese una contraseña válida",
        no_account: "¿No tienes una cuenta?",
        register: "Registrarse",
        send: "Enviar código de verificación",
        label_code: "Código de verificación",
        place_code: "Código de verificación",
        tip_code: "Ingrese el código de verificación",
        label_invitation: "Código de invitación",
        place_invitation: "Ingrese el código de invitación",
    },
    assets: {
        title: "Activos",
        total_assets: "Total de activos equivalentes",
        recharge: "Depositar",
        withdraw: "Retirar",
        available: "Disponible",
        freeze: "Congelado",
        total: "Total",
        label_bank: "Moneda",
        place_bank: "Seleccione una moneda",
        tip_bank: "Seleccione una moneda",
        label_wallet: "Billetera",
        place_wallet: "Seleccione una billetera",
        tip_wallet: "Seleccione una billetera",
        label_amount: "Cantidad",
        place_amount: "Ingrese la cantidad",
        tip_amount: "Ingrese una cantidad válida",
        label_password: "Contraseña",
        place_password: "Ingrese la contraseña",
        tip_password: "Ingrese una contraseña válida",
        label_balance: "USDT disponible",
        all: "Retirar todo",
        no_password: "Por seguridad de sus fondos, configure una",
        withdraw_psd: "contraseña de retiro",
        type1: "Cuenta Spot",
        type2: "Cuenta Fiat",
        type3: "Cuenta de Futuros",
        type4: "Cuenta de Futuros Rápidos",
        type5: "Cuenta de Finanzas",
        convert: "Convertir",
        now_currency: "Moneda actual",
        link_type: "Tipo de cadena",
        recharge_address: "Dirección de depósito",
        copy: "Copiar dirección",
        label_address: "Dirección de la billetera",
        place_address: "Ingrese la dirección de la billetera",
        submit_process: "Enviar para revisión",
        tip1: "No deposite ningún activo que no sea USDT a la dirección anterior, ya que los activos no serán recuperables.",
        tip2: "El depósito de USDT solo es compatible con el método de envío simple, y los depósitos realizados con otros métodos pueden no acreditarse temporalmente. Le agradecemos su comprensión.",
        tip3: "Una vez que deposite a la dirección anterior, la confirmación se llevará a cabo en toda la red. Los depósitos se acreditarán después de 1 confirmación de red, y se permitirán retiros después de 6 confirmaciones de red.",
        tip4: "Su dirección de depósito no cambiará con frecuencia y podrá reutilizarse para depósitos múltiples. Si hay cambios, haremos todo lo posible para notificarle a través de anuncios en la red o correos electrónicos.",
        tip5: "Asegúrese de que su computadora y navegador sean seguros para evitar la manipulación o divulgación de información.",
        record_title: "Historial de transacciones",
        label_number: "Cantidad",
        label_record: "Registro",
        label_time: "Hora",
        label_price: "Precio",
        label_volume: "Volumen",
        transfer_title: "Transferir",
        from: "Desde",
        to: "Hasta",
        transfer_record: "Historial de transferencias",
        label_withAddress: "Dirección de retiro",
        place_withAddress: "Configure primero su dirección de retiro",
        deposit_title: "Staking y Minería",
        buy: "Comprar",
        sell: "Vender",
        week: "Semana",
        daily_dividend: "Dividendo diario",
        deposit_log: "Historial de staking y minería",
        userinfo_nav1: "Verificación de identidad",
        userinfo_nav2: "Mi enlace de referencia",
        userinfo_nav3: "Mi código de autorización",
        userinfo_nav4: "Centro de seguridad",
        userinfo_nav5: "Métodos de pago",
        userinfo_nav6: "Atención al cliente en línea",
        userinfo_nav7: "Acerca de nosotros",
        userinfo_nav8: "Descargar la aplicación",
        userinfo_nav9: "Centro de ayuda",
        logout: "Cerrar sesión",
        second: "Contratos de segundos",
        second_title: "Contratos de segundos",
        invite: "Invitar",
        legal: "Comercio de moneda Fiat",
        ieo: "IEO",
        state0: "Todo",
        state1: "En precomercialización",
        state2: "En suscripción",
        state3: "Finalizado",
        history_record: "Historial",
        legal_tab1: "Comprar",
        legal_tab2: "Vender",
        transType: "Tipo de transacción",
        order_state: "Estado de la orden",
        order_state1: "No completada",
        order_state2: "Pagada",
        order_state3: "Confirmación pendiente",
        order_state4: "En disputa",
        order_state5: "Cancelada",
        order_state6: "Completada",
        reset: "Restablecer",
        hand_number: "Número de lotes",
        long: "Largo",
        short: "Corto",
        confirm_order: "Confirmar orden",
        type: "Tipo",
        multiple_number: "Número múltiple",
        now_entrust: "Órdenes abiertas",
        all_entrust: "Todas las órdenes",
        history_entrust: "Historial de órdenes",
        turnover: "Volumen de negociación",
        operate: "Operar",
        delete: "Eliminar",
        order_details: "Detalles de la orden",
        orderDetails_tab1: "Órdenes pendientes",
        orderDetails_tab2: "Órdenes en curso",
        orderDetails_tab3: "Órdenes cerradas",
        orderDetails_tab4: "Órdenes canceladas",
        risk_rate: "Índice de riesgo",
        total_pl: "Beneficio total",
        closing_position: "Cerrar todas las posiciones",
        position_list: "Lista de posiciones",
        order_record: "Historial de órdenes",
        profit_rate: "Tasa de beneficio",
        buy_long: "Comprar largo",
        buy_short: "Comprar corto",
        trans_mode: "Modo de transacción",
        opening_quantity: "Cantidad de apertura",
        opening_number: "Hora de apertura",
        place_transMount: "Ingrese el monto de la transacción",
        account_balance: "Saldo de la cuenta",
        privacy_policy: "Política de privacidad",
        contact_us: "Contáctenos",
        feedback: "Comentarios de los usuarios",
        feedback_type: "Tipo de comentario",
        feedback_type1: "Retiro relacionado",
        feedback_type2: "Blockchain relacionado",
        label_title: "Título",
        place_title: "Ingrese un título",
        label_cont: "Contenido del comentario",
        place_cont: "Ingrese el contenido",
        reset_password: "Restablecer contraseña",
        personal_center: "Centro personal",
        to_authenticate: "Autenticación",
        authenticate_tip1: "La autenticación puede aumentar su límite de retiro",
        authenticate_tip2: "Debe completar la autenticación de identidad para realizar retiros en el comercio de moneda Fiat",
        payment_method: "Métodos de pago",
        label_reaName: "Nombre real",
        palce_reaName: "Nombre real",
        label_openBank: "Banco",
        palce_openBank: "Ingrese el nombre del banco",
        label_bankNumber: "Número de cuenta bancaria",
        place_bankNumber: "Ingrese el número de cuenta bancaria",
        lable_branchBank: "Sucursal bancaria",
        place_branchBank: "Ingrese el nombre de la sucursal bancaria",
        label_IDnumber: "Número de identificación",
        place_IDnumber: "Ingrese el número de identificación",
        label_IDimg: "Foto del documento de identidad (anverso)",
        label_IDimg2: "Foto del documento de identidad (reverso)",
        tip_IDimg: "Cargue la foto del documento de identidad (reverso)",
        bankCard: "Tarjeta bancaria",
        details: "Detalles",
        my_authorization_code: "Mi código de autorización",
        my_authorization_code_tip: "Mi código de autorización",
        login_password: "Contraseña de inicio de sesión",
        trans_password: "Contraseña de transacción",
        modify: "Modificar",
        my_promotional_code: "Mi código promocional",
        promotional_code: "Código promocional",
        promotional_code_tip: "Escanea el código QR de arriba o ingresa el código promocional para registrarte",
        copy_link: "Copiar enlace de invitación",
        modify_trans_psd: "Modificar contraseña de transacción",
        label_email_verify: "Verificación de correo electrónico",
        place_email_verify: "Ingrese el código de verificación",
        send: "Enviar",
        email_verify_tip: "El código de verificación se enviará a su correo electrónico",
        label_bitTitle: "Par de transacción",
        label_profit: "Ganancia",
    },
    currency: {
        position: "Posición",
        drawer_title: "Intercambio de criptomonedas",
        now: "Actual",
        open: "Apertura",
        volume: "Volumen",
        height: "Máximo",
        low: "Mínimo",
        buy: "Comprar largo",
        sell: "Vender corto",
        name: "Nombre",
        direction: "Dirección",
        crm_order: "Confirmar pedido",
        now_price: "Precio actual",
        type_time: "Tiempo de liquidación (deslice a la izquierda para mayores ganancias)",
        second: "Segundo(s)",
        buy_amount: "Cantidad de compra",
        available: "Disponible",
        service_fee: "Tarifa de servicio",
        min_number: "Mínimo",
        errorTip1: "Entrada de monto incorrecta",
        errorTip2: "Saldo disponible insuficiente",
        auto_jump: "Saltar automáticamente al pedido",
        keep: "Continuar",
        revenue: "Ganancia estimada",
    },
    home: {
        quick: "Depósito rápido",
        support: "Soporte",
        nav1: "Compartir",
        nav2: "Billetera",
        nav3: "Noticias",
        nav4: "Tutoriales",
        nav5: "Soporte",
        nav6: "Minería",
        nav7: "Acerca de nosotros",
        nav8: "Nueva suscripción de monedas",
        increase: "Lista de ganancias",
        volume: "Volumen de operación",
        table_name: "Nombre",
        table_lastPrice: "Último precio",
        table_increase: "Variación de precio",
        table_volume: "Volumen de operación",
    },
    lockming: {
        profit: "Rendimiento diario",
        cycle: "Plazo de inversión",
        limit: "Límite por transacción",
        dividend_time: "Hora de pago de dividendos",
        every_day: "Diario",
        funds: "Fondos gestionados",
        back: "Regreso al vencimiento",
        ransom: "Rescate anticipado",
        label_amount: "Monto de la inversión",
        place_amount: "Ingrese...",
        available: "Disponible",
        expect_income: "Ingresos estimados",
        title: "Inversión bloqueada y minería",
        tip: "Gana sin parar con la minería",
        label_today_income: "Ingresos estimados hoy",
        label_total_income: "Ingresos acumulados",
        label_order: "Órdenes gestionadas",
        label_number: "Monto por transacción",
        daily_return: "Tasa de rendimiento diario",
        subscribe: "Suscribir",
        position: "Posición",
        label_state: "Estado",
        subscribe_time: "Hora de suscripción",
        expiration_time: "Hora de vencimiento",
        income: "Ingresos",
        errorTip1: "Ingrese el monto",
        tip_tit: "¿Seguro que desea rescatar antes del vencimiento?",
    },
    market: {
        spots: "Mercado de contado",
        contract: "Contrato",
        currency_trans: "Intercambio de monedas",
        increase: "Lista de ganancias",
        volume: "Volumen de operación",
        label_name: "Nombre",
        label_lastPrice: "Último precio",
        table_increase: "Variación de precio",
        table_volume: "Volumen de operación",
    },
    my: {
        details: "Detalles",
        help: "Centro de ayuda",
        information: "Noticias",
        language: "Cambio de idioma",
        password: {
            account_title: "Cambiar contraseña",
            withdraw_title: "Cambiar contraseña",
            label_oldPsd: "Contraseña anterior",
            place_oldPsd: "Ingrese la contraseña anterior",
            label_newPsd: "Nueva contraseña",
            place_newPsd: "Ingrese la nueva contraseña",
            label_crmPsd: "Confirmar contraseña",
            place_crmPsd: "Ingrese nuevamente la nueva contraseña",
            withdraw_tip: "Si no ha configurado una contraseña de retiro, déjelo en blanco",
        },
        address: {
            title: "Dirección de la billetera",
            account_number: "Número de cuenta",
            add_btn: "Agregar dirección",
            label_type: "Seleccionar tipo",
            place_type: "Seleccione el tipo",
            label_number: "Número de cuenta",
            place_number: "Ingrese el número de cuenta",
        },
        history: {
            title: "Historial",
            recharge_record: "Registro de recargas",
            withdraw_record: "Registro de retiros",
            recharge: "Recargar",
            withdraw: "Retirar",
            number: "Cantidad",
            currency: "Moneda",
            state: "Estado",
            state0: "Pendiente de revisión",
            state1: "Completado",
            state2: "Rechazado",
        },
        identity: {
            title: "Verificación de identidad",
            label_country: "Seleccione el país de origen",
            place_country: "Seleccione el país de origen",
            label_name: "Nombre",
            place_name: "Por favor, ingrese su nombre",
            label_id: "Número de documento",
            place_id: "Por favor, ingrese su número de identificación/pasaporte",
        },
        my: {
            list1: "Verificación de identidad",
            list2: "Gestión de órdenes",
            list3: "Historial financiero",
            list4: "Dirección de la billetera",
            list5: "Cambiar idioma",
            list6: "Invitar a amigos",
            list7: "Atención al cliente",
            list8: "Tutoriales",
            list9: "Cambiar contraseña",
            logout: "Cerrar sesión",
        },
        order: {
            title: "Posiciones",
            direction: "Dirección",
            sell_price: "Precio de orden",
            number: "Cantidad",
            type: "Tiempo de liquidación",
            buy_price: "Precio de compra",
            profit: "Ganancia/pérdida",
            sell_time: "Hora de venta",
        },
        share: {
            title: "Invitar a amigos",
            save_ewm: "Guardar código QR",
            invite_link: "Enlace de invitación",
            copy_address: "Copiar dirección",
            invite_code: "Código de registro",
            copy_code: "Copiar contenido",
            tip: "Regístrate a través del código QR o el enlace de arriba",
        },
    },
    transaction: {
        spots: "Mercado al contado",
        contract: "Contrato",
        currency_trans: "Intercambio de divisas",
        exchange: "Intercambio rápido",
        sustainable: "Contrato perpetuo",
        warehouse: "Contrato por lotes",
        jump_lever: "Cambiar apalancamiento",
        sell: "Vender",
        buy: "Comprar",
        available: "Disponible",
        check_price: "Precio límite",
        market_price: "Precio de mercado",
        number: "Cantidad",
        max: "Máximo",
        bail: "Garantía",
        buy_long: "Comprar largo",
        sell_short: "Vender corto",
        last_price: "Último precio",
        now_entrust: "Órdenes anteriores",
        now_position: "Posición actual",
        table_amount: "Monto a retirar",
        is_reduce: "Solo reducir posición",
        shi: "Sí",
        fou: "No",
        position_number: "Cantidad de la posición",
        is_profit: "Beneficio realizado",
        wei: "No",
        open_price: "Precio de apertura",
        mark_price: "Precio de marcado",
        flat_price: "Precio de liquidación",
        trim_lever: "Ajustar apalancamiento",
        flat_store: "Liquidar posición",
        trim_bail: "Ajustar garantía",
        reduce_bail: "Reducir garantía",
        now_bail: "Garantía actual de la posición",
        max_add: "Máximo aumento posible",
        refer_flat: "Precio de liquidación de referencia después del ajuste",
        expect_profit: "Beneficio esperado",
    },
    common: {
        confirm: "Confirmar",
        Cancel: "Cancelar",
        all: "Todo",
        close: "Cerrar",
        copy_success: "Copiado exitosamente",
        copy_error: "Error al copiar",
        place_input: "Por favor, ingrese contenido",
        more: "Más",
        main: "Gráfico principal",
        sub: "Gráfico secundario",
        success: "Éxito",
        cancel_tip: "¿Estás seguro de que deseas cancelar?",
        notice: "Aviso",
        logout_tip: "¿Estás seguro de que deseas cerrar sesión?",
        tip: "Consejo",
        day: "Día",
        place_inner: "Por favor, ingrese contenido",
        fail_banner: "Saldo insuficiente",
        empty: "Sin datos",
    },
    message: {
		cancel_order: "¿Deseas cancelar la orden?",
        stop_out: "¿Deseas liquidar la posición forzadamente?",
	},
    params: {
        transType0: "Spot",
        transType1: "Contrato",
        directionType0: "Compra",
        directionType1: "Venta",
        lockStateType0: "En curso",
        lockStateType1: "Finalizado",
        lockStateType2: "Cancelado",
	},
	city: {
        albania: "Albania",
        algeria: "Argelia",
        argentina: "Argentina",
        armenia: "Armenia",
        australia: "Australia",
        pakistan: "Pakistán",
        austria: "Austria",
        bahrain: "Baréin",
        belgium: "Bélgica",
        bosnia_and_Herzegovina: "Bosnia y Herzegovina",
        brazil: "Brasil",
        brunei: "Brunéi",
        bulgaria: "Bulgaria",
        cambodia: "Camboya",
        canada: "Canadá",
        cameroon: "Camerún",
        chile: "Chile",
        colombia: "Colombia",
        costa_Rica: "Costa Rica",
        croatia: "Croacia",
        cyprus: "Chipre",
        czech_Republic: "República Checa",
        denmark: "Dinamarca",
        dominican_Republic: "República Dominicana",
        egypt: "Egipto",
        estonia: "Estonia",
        ethiopia: "Etiopía",
        finland: "Finlandia",
        france: "Francia",
        georgia: "Georgia",
        germany: "Alemania",
        ghana: "Ghana",
        greece: "Grecia",
        guyana: "Guyana",
        honduras: "Honduras",
        hong_Kong_China: "Hong Kong (China)",
        hungary: "Hungría",
        iceland: "Islandia",
        ireland: "Irlanda",
        italy: "Italia",
        india: "India",
        indonesia: "Indonesia",
        israel: "Israel",
        iran: "Irán",
        iraq: "Irak",
        japan: "Japón",
        kazakhstan: "Kazajistán",
        kenya: "Kenia",
        korea: "Corea",
        kuwait: "Kuwait",
        kyrgyzstan: "Kirguistán",
        laos: "Laos",
        latvia: "Letonia",
        lithuania: "Lituania",
        luxembourg: "Luxemburgo",
        macao_China: "Macao (China)",
        macedonia: "Macedonia",
        malaysia: "Malasia",
        malta: "Malta",
        mexico: "México",
        moldova: "Moldavia",
        monaco: "Mónaco",
        mongolia: "Mongolia",
        montenegro: "Montenegro",
        morocco: "Marruecos",
        myanmar: "Myanmar",
        netherlands: "Países Bajos",
        new_Zealand: "Nueva Zelanda",
        nepal: "Nepal",
        nigeria: "Nigeria",
        norway: "Noruega",
        oman: "Omán",
        palestine: "Palestina",
        panama: "Panamá",
        paraguay: "Paraguay",
        peru: "Perú",
        philippines: "Filipinas",
        poland: "Polonia",
        portugal: "Portugal",
        puerto_Rico: "Puerto Rico",
        qatar: "Catar",
        romania: "Rumania",
        russia: "Rusia",
        republic_of_Trinidad_and_Tobago: "República de Trinidad y Tobago",
        rwanda: "Ruanda",
        saudi_Arabia: "Arabia Saudita",
        serbia: "Serbia",
        singapore: "Singapur",
        slovakia: "Eslovaquia",
        slovenia: "Eslovenia",
        south_Africa: "Sudáfrica",
        spain: "España",
        sri_Lanka: "Sri Lanka",
        sweden: "Suecia",
        switzerland: "Suiza",
        taiwan_China: "Taiwán (China)",
        tajikistan: "Tayikistán",
        tanzania: "Tanzania",
        thailand: "Tailandia",
        turkey: "Turquía",
        turkmenistan: "Turkmenistán",
        ukraine: "Ucrania",
        united_Arab_Emirates: "Emiratos Árabes Unidos",
        united_Kingdom: "Reino Unido",
        united_States: "Estados Unidos",
        uzbekistan: "Uzbekistán",
        venezuela: "Venezuela",
        vietnam: "Vietnam",
        afghanistan: "Afganistán",
        angola: "Angola",
        azerbaijan: "Azerbaiyán",
        bangladesh: "Bangladesh",
        belarus: "Bielorrusia",
        belize: "Belice",
        benin: "Benín",
        bhutan: "Bután",
        bolivia: "Bolivia",
        botswana: "Botsuana",
        british_Virgin_Islands: "Islas Vírgenes Británicas",
        burkina_Faso: "Burkina Faso",
        burundi: "Burundi",
        cape_Verde: "Cabo Verde",
        cayman_Islands: "Islas Caimán",
        central_African_Republic: "República Centroafricana",
        chad: "Chad",
        comoros: "Comoras",
        the_Republic_of_the_Congo: "República del Congo",
        democratic_Republic_of_the_Congo: "República Democrática del Congo",
        djibouti: "Yibuti",
        ecuador: "Ecuador",
        el_Salvador: "El Salvador",
        equatorial_Guinea: "Guinea Ecuatorial",
        eritrea: "Eritrea",
        fiji: "Fiyi",
        gabon: "Gabón",
        gambia: "Gambia",
        greenland: "Groenlandia",
        guatemala: "Guatemala",
        guinea: "Guinea",
        haiti: "Haití",
        isle_of_Man: "Isla de Man",
        cote_d_Ivoire: "Costa de Marfil",
        jamaica: "Jamaica",
        jordan: "Jordania",
        lebanon: "Líbano",
        lesotho: "Lesoto",
        liberia: "Liberia",
        libya: "Libia",
        madagascar: "Madagascar",
        malawi: "Malaui",
        maldives: "Maldivas",
        mali: "Malí",
        mauritania: "Mauritania",
        mauritius: "Mauricio",
        mozambique: "Mozambique",
        namibia: "Namibia",
        nicaragua: "Nicaragua",
        republic_of_Niger: "República de Níger",
        north_Korea: "Corea del Norte",
        reunion: "Reunión",
        san_Marino: "San Marino",
        senegal: "Senegal",
        sierra_Leone: "Sierra Leona",
        somalia: "Somalia",
        sudan: "Sudán",
        suriname: "Surinam",
        eswatini: "Esuatini",
        syria: "Siria",
        togo: "Togo",
        tonga: "Tonga",
        tunisia: "Túnez",
        united_States_Virgin_Islands: "Islas Vírgenes de los Estados Unidos",
        uganda: "Uganda",
        uruguay: "Uruguay",
        vatican: "Ciudad del Vaticano",
        yemen: "Yemen",
        yugoslavia: "Yugoslavia",
        zambia: "Zambia",
        zimbabwe: "Zimbabue",
        china: "China",
    }
};
